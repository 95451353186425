import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { login, getMyself, logout, User, LoginCredentials, JwtPayload } from '../api/auth';
import { jwtDecode } from "jwt-decode";
import { getTermsOfService } from '../api/termsOfService';
import { getBillingInfo } from '../api/billing';
import { AdminCompany, listCompaniesForAdmin } from "../api/company";
import { Business, listBusinessesForAdmin } from "../api/business";

interface AuthContextType {
  user: User | null;
  login: (credentials: LoginCredentials) => Promise<User>;
  logout: () => Promise<void>;
  loading: boolean;
  termsAccepted: boolean | null;
  checkTermsAcceptance: () => Promise<void>;
  isActionRequired: boolean;
  checkBillingStatus: () => Promise<void>;
  selectedCompany: AdminCompany | null;
  setSelectedCompany: (company: AdminCompany | null) => void;
  selectedMerchant: Business | null;
  setSelectedMerchant: (merchant: Business | null) => void;
  cachedCompanies: AdminCompany[];
  fetchCompanies: () => Promise<AdminCompany[]>;
  cachedMerchants: Record<string, Business[]>;
  fetchMerchants: (companyId: string) => Promise<Business[]>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState<boolean | null>(null);
  const [isActionRequired, setActionRequired] = useState(false);
  const [selectedCompany, _setSelectedCompany] = useState<AdminCompany | null>(
    null
  );
  const [selectedMerchant, setSelectedMerchant] = useState<Business | null>(
    null
  );
  const [cachedCompanies, setCachedCompanies] = useState<AdminCompany[]>([]);
  const [cachedMerchants, setCachedMerchants] = useState<
    Record<string, Business[]>
  >({});

  const fetchCompanies = async () => {
    // Return cached companies if available
    if (cachedCompanies.length > 0) {
      return cachedCompanies;
    }

    try {
      const companies = await listCompaniesForAdmin();
      setCachedCompanies(companies);
      return companies;
    } catch (error) {
      console.error("Error fetching companies:", error);
      return [];
    }
  };

  const fetchMerchants = async (companyId: string) => {
    // Return cached merchants if available for this company
    if (cachedMerchants[companyId]) {
      return cachedMerchants[companyId];
    }

    try {
      const merchants = await listBusinessesForAdmin(companyId);
      setCachedMerchants((prev) => ({
        ...prev,
        [companyId]: merchants,
      }));
      return merchants;
    } catch (error) {
      console.error("Error fetching merchants:", error);
      return [];
    }
  };

  const setSelectedCompany = (company: AdminCompany | null) => {
    _setSelectedCompany(company);
    // Clear selected merchant when company is unselected
    if (!company) {
      setSelectedMerchant(null);
    }
  };

  const checkTermsAcceptance = async () => {
    try {
      const response = await getTermsOfService();
      setTermsAccepted(response.accepted);
    } catch (error) {
      console.error("Error checking terms acceptance:", error);
      setTermsAccepted(null);
    }
  };

  const checkBillingStatus = async () => {
    try {
      const response = await getBillingInfo();
      setActionRequired(response.billingInfo?.actionRequired ?? false);
    } catch (error) {
      console.error("Error checking billing status:", error);
      setActionRequired(false);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await getMyself();
        setUser(user);
        if (!user.isAdminAccount) {
          await Promise.all([checkTermsAcceptance(), checkBillingStatus()]);
        }
      } catch (error) {
        setUser(null);
        setTermsAccepted(null);
        setActionRequired(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const loginUser = async (credentials: LoginCredentials) => {
    const response = await login(credentials);
    const user = await getMyself();
    setUser(user);
    if (!user.isAdminAccount) {
      await checkTermsAcceptance();
    }
    return user;
  };

  const logoutUser = async () => {
    await logout();
    setUser(null);
    // Clear caches on logout
    setCachedCompanies([]);
    setCachedMerchants({});
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login: loginUser,
        logout: logoutUser,
        loading,
        termsAccepted,
        checkTermsAcceptance,
        isActionRequired: isActionRequired,
        checkBillingStatus,
        selectedCompany,
        setSelectedCompany,
        selectedMerchant,
        setSelectedMerchant,
        cachedCompanies,
        fetchCompanies,
        cachedMerchants,
        fetchMerchants,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};