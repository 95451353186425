import React, { ChangeEvent, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { TextField } from './TextField';
import { Button } from './Button';
import { ROUTES } from '../config/routes';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const { login } = useAuth();
  const navigate = useNavigate();
  const passwordResetSuccess = searchParams.has("resetSuccess");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      const user = await login({ Email: email, Password: password });
      if (user?.isAdminAccount) {
        navigate(ROUTES.ADMIN_HOME());
      } else {
        navigate(ROUTES.HOME());
      }
    } catch (error: any) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        setError("Invalid email or password");
      } else {
        setError("An error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className="flex flex-col w-full gap-4 bg-white rounded-3xl p-4 md:max-w-[424px]"
      onSubmit={handleSubmit}
    >
      <h2 className="text-primary font-semibold text-center mb-2">Log in</h2>

      <TextField
        type="email"
        label="Email"
        value={email}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)
        }
        required
      />
      <div className="flex flex-col mb-[30px]">
        <TextField
          type="password"
          label="Password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          required
        />
        <Link
          to={ROUTES.REQUEST_PASSWORD_RESET()}
          className="text-primary text-sm ml-auto"
        >
          Reset password
        </Link>
      </div>
      {passwordResetSuccess && (
        <p className="text-center text-sm mb-4">
          Password reset successful. Please log in with your new password.
        </p>
      )}
      {error && (
        <p className="text-red-500 text-sm text-center mb-4">{error}</p>
      )}
      <Button
        type="submit"
        size="lg"
        className="submit-button"
        disabled={isLoading}
      >
        {isLoading ? "Logging in..." : "Log in"}
      </Button>
      <Link
        to="https://www.oisterbonus.dk/partner"
        className="text-primary underline text-center"
      >
        Become a partner
      </Link>
    </form>
  );
};

export default LoginForm;
