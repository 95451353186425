import api from './axiosConfig';
import { Benefit } from './benefits'
import { BusinessCategory } from './businessCategories'
import { PaymentProvider } from './paymentProviders'
import { ExternalLinks } from './externalLinks'
import { Image } from './common'

export interface Business {
  id: string;
  name: string;
  merchantContractNumbers: string[];
  businessCategories: BusinessCategory[];
  description: string;
  location: { lon: number; lat: number } | null;
  logo: Image;
  mainImage: Image;
  images: Image[];
  benefits: Benefit[];
  paymentProviders: PaymentProvider[];
  externalLinks: ExternalLinks;
  billingIdentificationNumber: string;
  alternativeMerchantNames: string[];
  hasPendingChanges: boolean | null;
}

export interface CreateBusinessInput {
    name: string;
    merchantContractNumbers: string[];
    businessCategoryIds: string[];
    description: string;
    location: { lon: number; lat: number } | null;
    logoId: string;
    mainImageId: string;
    imageIds: string[];
    benefitIds: string[];
    paymentProviderIds: string[];
    externalLinksId: string;
    billingIdentificationNumber: string;
    alternativeMerchantNames: string[];
  }

export const createBusiness = async (input: CreateBusinessInput): Promise<void> => {
    try {
        const response = await api.post('/business-account/create-or-update-business', { CreateOrUpdateBusinessInput: input });
        return response.data;
      } catch (error) {
        console.error('Creating business error:', error);
        throw error;
      }
};

export const updateBusiness = async (id: string, input: CreateBusinessInput): Promise<void> => {
    try {
        const response = await api.post('/business-account/create-or-update-business', { CreateOrUpdateBusinessInput: { ...input, Id: id } });
        return response.data;
      } catch (error) {
        console.error('Creating business error:', error);
        throw error;
      }
};

export const getBusiness = async (id: string): Promise<Business> => {
    try {
        const response = await api.post(`/business-account/get-business`, {businessId: id});
        return response.data;
      } catch (error) {
        console.error('Creating business error:', error);
        throw error;
      } 
};

export const listBusinesses = async (): Promise<Business[]> => {
    try {
        const response = await api.post('/business-account/list-businesses', {});
        return response.data;
      } catch (error) {
        console.error('Creating business error:', error);
        throw error;
      } 
};

export const listBusinessesForAdmin = async (
  companyId: string
): Promise<Business[]> => {
  try {
    const response = await api.post(
      "/business-account/list-businesses-for-admin",
      { companyId }
    );
    return response.data;
  } catch (error) {
    console.error("Listing businesses for admin error:", error);
    throw error;
  }
};