import React, { useState, useEffect } from "react";
import { getInsightsForAdmin } from "../api/insights";
import { Loader } from "../components/Loader";
import { InsightsForAdminResponse } from "../api/insights";
import { CompanySelect } from "../components/CompanySelect";
import { MerchantSelect } from "../components/MerchantSelect";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Legend,
} from "recharts";
import { AgeGroup, GenderGroup, ScreenViewGroup } from "../api/insights";
import { useAuth } from "../context/AuthContext";

const BAR_COLORS = [
  "rgb(99 102 241 / 0.7)",
  "rgb(236 72 153 / 0.7)",
  "rgb(34 197 94 / 0.7)",
  "rgb(245 158 11 / 0.7)",
  "rgb(139 92 246 / 0.7)",
  "rgb(6 182 212 / 0.7)",
];

const PIE_COLORS = {
  Male: "rgb(99 102 241 / 0.7)",
  Female: "rgb(236 72 153 / 0.7)",
  Other: "rgb(34 197 94 / 0.7)", // green color for "Other"
  NotSelected: "rgb(209 213 219 / 0.7)", // light gray
};

const PLATFORM_COLORS = {
  IOS: "rgb(99 102 241 / 0.7)", // indigo
  Android: "rgb(34 197 94 / 0.7)", // green
};

const AdminInsights: React.FC = () => {
  const { selectedCompany, selectedMerchant } = useAuth();
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [insights, setInsights] = useState<InsightsForAdminResponse | null>(
    null
  );

  useEffect(() => {
    fetchInsights();
  }, [selectedCompany, selectedMerchant]);

  const fetchInsights = async () => {
    try {
      setLoading(true);
      const response = await getInsightsForAdmin(
        selectedCompany?.id,
        selectedMerchant?.id
      );
      setInsights(response);
      setError(null);
    } catch (err) {
      setError("Failed to fetch insights");
      console.error("Error fetching insights:", err);
    } finally {
      setLoading(false);
      setInitialLoad(false);
    }
  };

  const prepareChartData = (data: {
    ageGroups: AgeGroup[];
    genderGroups: GenderGroup[];
    screenViewGroups: ScreenViewGroup[];
  }) => {
    const ageData = data.ageGroups.map((group) => {
      const total = data.ageGroups.reduce(
        (sum, g) => sum + g.customersCount,
        0
      );
      const percentage =
        total === 0 ? "0.0" : ((group.customersCount / total) * 100).toFixed(1);
      return {
        name: group.ageRange,
        value: group.customersCount,
        label: `${percentage}%`,
      };
    });

    const genderData = data.genderGroups.map((group) => {
      const total = data.genderGroups.reduce(
        (sum, g) => sum + g.customersCount,
        0
      );
      const percentage =
        total === 0 ? "0.0" : ((group.customersCount / total) * 100).toFixed(1);
      return {
        name: group.gender,
        value: group.customersCount,
        label: `${group.gender} ${percentage}%`,
        color: PIE_COLORS[group.gender as keyof typeof PIE_COLORS],
      };
    });

    const screenViewData = data.screenViewGroups.map((group) => {
      const total = data.screenViewGroups.reduce(
        (sum, g) => sum + g.screenViews,
        0
      );
      const percentage =
        total === 0 ? "0.0" : ((group.screenViews / total) * 100).toFixed(1);
      return {
        name: group.platform,
        value: group.screenViews,
        label: `${group.platform} ${percentage}%`,
        color: PLATFORM_COLORS[group.platform as keyof typeof PLATFORM_COLORS],
        total,
      };
    });

    return { ageData, genderData, screenViewData };
  };

  const renderCustomLegend = (data: any[]) => (
    <ul className="flex justify-center mt-4">
      {data.map((entry, index) => (
        <li key={`item-${index}`} className="mx-2">
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: data[index].color,
              marginRight: "5px",
            }}
          />
          {entry.label}
        </li>
      ))}
    </ul>
  );

  // Show full page loader only on initial load
  if (initialLoad) {
    return (
      <div className="loading-page">
        <Loader />
      </div>
    );
  }

  if (!insights) return null;

  const chartData = prepareChartData(insights);

  const InsightsSection = ({
    title,
    data,
    totalCount,
  }: {
    title: string;
    data: { ageData: any[]; genderData: any[]; screenViewData: any[] };
    totalCount: number;
  }) => (
    <>
      <div className="flex max-md:flex-col md:items-center md:justify-between mb-10 max-md:gap-4">
        <div>
          <h2 className="font-medium">{title}</h2>
          <p className="text-sm text-gray-500 mt-1">
            Data is refreshed approximately every hour
          </p>
        </div>
      </div>

      {/* Show inline loader during subsequent data fetches */}
      {loading && !initialLoad && (
        <div className="flex justify-center my-8">
          <Loader />
        </div>
      )}

      {error && <div className="error mb-4">{error}</div>}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 text-center">
            Total Customers
          </h3>
          <div className="h-[300px] flex items-center justify-center">
            <div className="w-40 h-40 rounded-full border-[12px] border-[#6366F1]/20 flex items-center justify-center">
              <span className="text-4xl font-bold text-[#6366F1]/60">
                {totalCount.toLocaleString()}
              </span>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 text-center">
            Age Distribution
          </h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data.ageData}
                margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
              >
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  interval={0}
                />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8">
                  {data.ageData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={BAR_COLORS[index % BAR_COLORS.length]}
                    />
                  ))}
                  <LabelList dataKey="label" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 text-center">
            Gender Distribution
          </h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data.genderData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  isAnimationActive={false}
                  labelLine={false}
                >
                  {data.genderData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={() => renderCustomLegend(data.genderData)} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 text-center">
            Profile Screen Views
          </h3>
          <div className="h-[300px] relative">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data.screenViewData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  isAnimationActive={false}
                  labelLine={false}
                >
                  {data.screenViewData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  content={() => renderCustomLegend(data.screenViewData)}
                />
              </PieChart>
            </ResponsiveContainer>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="transform translate-y-[-8px]">
                <div className="text-xl font-bold text-[#6366F1]/60">
                  {data.screenViewData[0]?.total.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <div className="flex flex-col gap-4 mb-10">
        <h2 className="font-medium">
          Insights -{" "}
          {selectedCompany
            ? selectedMerchant
              ? `${selectedCompany.name} / ${selectedMerchant.name}`
              : selectedCompany.name
            : "All Companies"}
        </h2>
        <div className="flex items-center gap-4 mb-4">
          <div className="w-[300px]">
            <CompanySelect />
          </div>
          {selectedCompany && (
            <div className="w-[300px]">
              <MerchantSelect />
            </div>
          )}
        </div>
      </div>
      <InsightsSection
        title={
          selectedCompany ? "Company Customers" : "All OisterBonus Customers"
        }
        data={chartData}
        totalCount={insights.totalCustomersCount}
      />
    </div>
  );
};

export default AdminInsights;
