import React, { useState, useEffect } from 'react';
import { Button } from '../components/Button';
import { Loader } from '../components/Loader';
import { listTransactions, Transaction } from '../api/transactions';
import {
  formatNumber,
  formatCurrency,
  formatInteger,
} from "../helpers/formatNumber";

const PAGE_SIZE = 100;

const Transactions: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [stats, setStats] = useState<{
    totalTransactionAmount: number;
    totalAssignedCashback: number;
  }>({
    totalTransactionAmount: 0,
    totalAssignedCashback: 0,
  });

  useEffect(() => {
    fetchTransactions();
  }, [pageNumber]);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const response = await listTransactions({
        pageNumber,
        pageSize: PAGE_SIZE,
      });
      setTransactions(response.data.data);
      setTotal(response.data.total);
      setStats(response.stats);
      setError(null);
    } catch (err) {
      setError("Failed to fetch transactions");
      console.error("Error fetching transactions:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    setPageNumber((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    setPageNumber((prev) => Math.max(1, prev - 1));
  };

  if (loading)
    return (
      <div className="loading-page">
        <Loader />
      </div>
    );

  if (error) return <div className="error">{error}</div>;

  const totalPages = Math.ceil(total / PAGE_SIZE);

  return (
    <div>
      <div className="flex flex-col gap-4 mb-10">
        <h2 className="font-medium">Transaction List</h2>
        <div className="flex gap-4">
          <div className="bg-white rounded-lg shadow p-4 min-w-[200px] w-fit">
            <div className="text-gray-500 text-sm">Total Transactions</div>
            <div className="text-2xl font-semibold mt-1">
              {formatInteger(total)}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow p-4 min-w-[200px] w-fit">
            <div className="text-gray-500 text-sm">
              Total Transaction Amount
            </div>
            <div className="text-2xl font-semibold mt-1">
              {formatCurrency(stats.totalTransactionAmount)}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow p-4 min-w-[200px] w-fit">
            <div className="text-gray-500 text-sm">Total Assigned Cashback</div>
            <div className="text-2xl font-semibold mt-1">
              {formatCurrency(stats.totalAssignedCashback)}
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="table-default">
          <thead>
            <tr>
              <th>Merchant Name</th>
              <th className="max-w-[120px]">Transaction Amount</th>
              <th className="max-w-[120px]">Total Assigned Cashback</th>
              <th className="whitespace-nowrap">
                Cashback Assignment Time UTC
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.merchantName}</td>
                <td className="max-w-[120px]">
                  {formatCurrency(transaction.transactionAmount)}
                </td>
                <td className="max-w-[120px]">
                  {formatCurrency(transaction.totalAssignedCashback)}
                </td>

                <td className="whitespace-nowrap">
                  {new Date(transaction.cashbackAssignmentTimeUTC)
                    .toLocaleString("pl", {
                      dateStyle: "short",
                      timeStyle: "short",
                      timeZone: "UTC",
                    })
                    .replace(/\./g, "/")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <Button
          onClick={handlePreviousPage}
          disabled={pageNumber === 1}
          variant="outline"
        >
          Previous
        </Button>
        <span>
          Page {pageNumber} of {totalPages}
        </span>
        <Button
          onClick={handleNextPage}
          disabled={pageNumber >= totalPages}
          variant="outline"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Transactions;
