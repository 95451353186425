import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Business } from "../api/business";
import { useAuth } from "../context/AuthContext";

interface MerchantOption {
  value: string;
  label: string;
}

export const MerchantSelect: React.FC = () => {
  const {
    selectedCompany,
    selectedMerchant,
    setSelectedMerchant,
    cachedMerchants,
    fetchMerchants,
  } = useAuth();

  // Only show loading state when we're fetching for the first time for this company
  const isInitialLoad = !!(
    selectedCompany && !cachedMerchants[selectedCompany.id]
  );
  const merchants = selectedCompany
    ? cachedMerchants[selectedCompany.id] || []
    : [];

  useEffect(() => {
    const loadMerchants = async () => {
      if (selectedCompany && !cachedMerchants[selectedCompany.id]) {
        try {
          await fetchMerchants(selectedCompany.id);
        } catch (err) {
          console.error("Error fetching merchants:", err);
        }
      }
    };

    loadMerchants();
  }, [selectedCompany?.id]); // Only depend on company ID

  return (
    <Select<MerchantOption>
      value={
        selectedMerchant
          ? {
              value: selectedMerchant.id,
              label: selectedMerchant.name,
            }
          : null
      }
      onChange={(option) => {
        const merchant = option
          ? merchants.find((m) => m.id === option.value) ?? null
          : null;
        setSelectedMerchant(merchant);
      }}
      options={merchants.map((merchant) => ({
        value: merchant.id,
        label: merchant.name,
      }))}
      isLoading={isInitialLoad}
      isClearable
      placeholder={
        isInitialLoad ? "Loading merchants..." : "Filter by merchant..."
      }
      className="basic-select"
      classNamePrefix="select"
    />
  );
};
