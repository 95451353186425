/**
 * Formats a number to Danish format (2.183.166,87)
 * Uses . as thousands separator and , as decimal separator
 */
export const formatNumber = (value: number, decimals: number = 2): string => {
  // First convert to fixed decimal places
  const fixedValue = value.toFixed(decimals);

  // Split into integer and decimal parts
  const [integerPart, decimalPart] = fixedValue.split(".");

  // Add thousand separators to integer part
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  );

  // Combine with decimal part, using comma as decimal separator
  return decimalPart
    ? `${formattedIntegerPart},${decimalPart}`
    : formattedIntegerPart;
};

/**
 * Formats an integer with thousand separators in Danish format (2.183.166)
 * No decimal places, uses . as thousands separator
 */
export const formatInteger = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

/**
 * Formats currency values (divides by 100 first, then formats)
 */
export const formatCurrency = (value: number, decimals: number = 2): string => {
  return formatNumber(value / 100, decimals);
};
