import React, { useEffect } from "react";
import { AdminCompany } from "../api/company";
import { useAuth } from "../context/AuthContext";
import Select from "react-select";

interface CompanyOption {
  value: string;
  label: string;
}

export const CompanySelect: React.FC = () => {
  const {
    selectedCompany,
    setSelectedCompany,
    cachedCompanies,
    fetchCompanies,
  } = useAuth();

  // Only show loading when we haven't loaded any companies yet
  const isInitialLoad = cachedCompanies.length === 0;

  useEffect(() => {
    const loadCompanies = async () => {
      if (cachedCompanies.length === 0) {
        try {
          const companies = await fetchCompanies();
          // If there's only one company, auto-select it
          if (companies.length === 1 && !selectedCompany) {
            setSelectedCompany(companies[0]);
          }
        } catch (error) {
          console.error("Failed to fetch companies:", error);
        }
      }
    };

    loadCompanies();
  }, []); // Only run once on mount

  return (
    <div className="mb-0">
      <Select<CompanyOption>
        value={
          selectedCompany
            ? {
                value: selectedCompany.id,
                label: selectedCompany.name,
              }
            : null
        }
        onChange={(option) => {
          const company = option
            ? cachedCompanies.find((c) => c.id === option.value) ?? null
            : null;
          setSelectedCompany(company);
        }}
        options={cachedCompanies.map((company) => ({
          value: company.id,
          label: company.name,
        }))}
        isLoading={isInitialLoad}
        isClearable
        placeholder={
          isInitialLoad ? "Loading companies..." : "Filter by company..."
        }
        className="basic-select"
        classNamePrefix="select"
      />
    </div>
  );
};
