import api from './axiosConfig';

export interface Company {
    name: string;
    email: string;
    cvrNumber: string;
    hasPendingChanges: boolean | null;
  }

export interface AdminCompany {
  id: string;
  name: string;
  email: string;
  cvrNumber: string;
  billingIdentificationNumber: string;
  hasPendingChanges: boolean;
}

export interface UpdateCompanyInput {
  name: string;
  email: string;
  cvrNumber: string;
}

export const updateCompany = async (input: UpdateCompanyInput) => {
  try {
    const response = await api.post("/business-account/update-company", {
      UpdateCompanyInput: input,
    });
    return response.data;
  } catch (error) {
    console.error("Updating company error:", error);
    throw error;
  }
};

export const getCompany = async (): Promise<Company> => {
  try {
    const response = await api.post("/business-account/get-company", {});
    return response.data;
  } catch (error) {
    console.error("Getting company error:", error);
    throw error;
  }
};

export const listCompaniesForAdmin = async (): Promise<AdminCompany[]> => {
  try {
    const response = await api.post(
      "/business-account/list-companies-for-admin",
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Listing companies for admin error:", error);
    throw error;
  }
};