import api from './axiosConfig';

export interface Transaction {
  transactionAmount: number;
  cardAcceptorInfo: string;
  totalAssignedCashback: number;
  customerAssignedCashback: number;
  merchantName: string;
  reportedMerchantCvr: string;
  reportedTransactionTime: string;
  cashbackAssignmentTimeUTC: string;
  source: string;
}

export interface PaginationRequest {
  pageNumber: number;
  pageSize: number;
}

interface TransactionStats {
  totalTransactionAmount: number;
  totalAssignedCashback: number;
}

interface TransactionResponse {
  data: PaginatedResponse<Transaction>;
  stats: TransactionStats;
}

export interface PaginatedResponse<T> {
  pageNumber: number;
  pageSize: number;
  total: number;
  data: T[];
}

export interface AdminTransactionRequest extends PaginationRequest {
  companyId?: string;
  merchantId?: string;
  from?: string; // ISO 8601 format
  to?: string; // ISO 8601 format
}

export const listTransactions = async (
  pagination: PaginationRequest
): Promise<TransactionResponse> => {
  try {
    const response = await api.post("/business-account/list-transactions", {
      pagination,
    });
    return response.data;
  } catch (error) {
    console.error("Listing transactions error:", error);
    throw error;
  }
};

export const listTransactionsForAdmin = async (
  request: AdminTransactionRequest
): Promise<TransactionResponse> => {
  try {
    const response = await api.post(
      "/business-account/list-transactions-for-admin",
      {
        pagination: {
          pageNumber: request.pageNumber,
          pageSize: request.pageSize,
        },
        companyId: request.companyId,
        merchantId: request.merchantId,
        from: request.from,
        to: request.to,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Listing transactions for admin error:", error);
    throw error;
  }
};
