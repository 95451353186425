import React, { useState, useEffect } from "react";
import { Button } from "../components/Button";
import { Loader } from "../components/Loader";
import { listTransactionsForAdmin, Transaction } from "../api/transactions";
import { useAuth } from "../context/AuthContext";
import { CompanySelect } from "../components/CompanySelect";
import { MerchantSelect } from "../components/MerchantSelect";
import {
  formatNumber,
  formatCurrency,
  formatInteger,
} from "../helpers/formatNumber";

const PAGE_SIZE = 100;

const AdminTransactions: React.FC = () => {
  const { selectedCompany, selectedMerchant } = useAuth();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [stats, setStats] = useState<{
    totalTransactionAmount: number;
    totalAssignedCashback: number;
  }>({
    totalTransactionAmount: 0,
    totalAssignedCashback: 0,
  });

  useEffect(() => {
    fetchTransactions();
  }, [pageNumber, selectedCompany, selectedMerchant, startDate, endDate]);

  const fetchTransactions = async () => {
    try {
      setLoading(true);

      // Create ISO strings treating input as UTC time
      let fromDateString: string | undefined = undefined;
      let toDateString: string | undefined = undefined;

      if (startDate) {
        // Directly create UTC date by appending 'Z' to the input date string
        fromDateString = startDate + "Z";
      }

      if (endDate) {
        // Directly create UTC date by appending 'Z' to the input date string
        toDateString = endDate + "Z";
      }

      const response = await listTransactionsForAdmin({
        pageNumber,
        pageSize: PAGE_SIZE,
        companyId: selectedCompany?.id,
        merchantId: selectedMerchant?.id,
        from: fromDateString,
        to: toDateString,
      });
      setTransactions(response.data.data);
      setTotal(response.data.total);
      setStats(response.stats);
      setError(null);
    } catch (err) {
      setError("Failed to fetch transactions");
      console.error("Error fetching transactions:", err);
    } finally {
      setLoading(false);
      setInitialLoad(false);
    }
  };

  const handleNextPage = () => {
    setPageNumber((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    setPageNumber((prev) => Math.max(1, prev - 1));
  };

  // Show full page loader only on initial load
  if (initialLoad) {
    return (
      <div className="loading-page">
        <Loader />
      </div>
    );
  }

  const totalPages = Math.ceil(total / PAGE_SIZE);

  return (
    <div>
      <div className="flex flex-col gap-4 mb-10">
        <h2 className="font-medium">
          Transactions -{" "}
          {selectedCompany
            ? selectedMerchant
              ? `${selectedCompany.name} / ${selectedMerchant.name}`
              : selectedCompany.name
            : "All Companies"}
        </h2>
        <div className="flex items-center gap-4 mb-4">
          <div className="w-[300px]">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Company
            </label>
            <CompanySelect />
          </div>
          {selectedCompany && (
            <div className="w-[300px]">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Merchant
              </label>
              <MerchantSelect />
            </div>
          )}
          <div className="w-[250px]">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Start Date and Time (UTC)
            </label>
            <input
              type="datetime-local"
              className="w-full p-2 border border-gray-300 rounded-md"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="w-[250px]">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              End Date and Time (UTC)
            </label>
            <input
              type="datetime-local"
              className="w-full p-2 border border-gray-300 rounded-md"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="bg-white rounded-lg shadow p-4 min-w-[200px] w-fit">
            <div className="text-gray-500 text-sm">Total Transactions</div>
            <div className="text-2xl font-semibold mt-1">
              {formatInteger(total)}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow p-4 min-w-[200px] w-fit">
            <div className="text-gray-500 text-sm">
              Total Transaction Amount
            </div>
            <div className="text-2xl font-semibold mt-1">
              {formatCurrency(stats.totalTransactionAmount)}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow p-4 min-w-[200px] w-fit">
            <div className="text-gray-500 text-sm">Total Assigned Cashback</div>
            <div className="text-2xl font-semibold mt-1">
              {formatCurrency(stats.totalAssignedCashback)}
            </div>
          </div>
        </div>
      </div>

      {/* Show inline loader during subsequent data fetches */}
      {loading && !initialLoad && (
        <div className="flex justify-center my-8">
          <Loader />
        </div>
      )}

      {error && <div className="error mb-4">{error}</div>}

      <div className="overflow-x-auto">
        <table className="table-default">
          <thead>
            <tr>
              <th>Merchant Name</th>
              <th className="max-w-[120px]">Transaction Amount</th>
              <th className="max-w-[120px]">Total Assigned Cashback</th>
              <th className="whitespace-nowrap">
                Cashback Assignment Time UTC
              </th>
              <th>Transaction Source</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.merchantName}</td>
                <td className="max-w-[120px]">
                  {formatCurrency(transaction.transactionAmount)}
                </td>
                <td className="max-w-[120px]">
                  {formatCurrency(transaction.totalAssignedCashback)}
                </td>
                <td className="whitespace-nowrap">
                  {new Date(transaction.cashbackAssignmentTimeUTC)
                    .toLocaleString("pl", {
                      dateStyle: "short",
                      timeStyle: "short",
                      timeZone: "UTC",
                    })
                    .replace(/\./g, "/")}
                </td>
                <td>{transaction.source}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <Button
          onClick={handlePreviousPage}
          disabled={pageNumber === 1}
          variant="outline"
        >
          Previous
        </Button>
        <span>
          Page {pageNumber} of {totalPages}
        </span>
        <Button
          onClick={handleNextPage}
          disabled={pageNumber >= totalPages}
          variant="outline"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AdminTransactions;
