import React from "react";
import { Card, CardHeader } from "../components/Card";
import { ROUTES } from "../config/routes";

const AdminHome: React.FC = () => {
  return (
    <>
      <div className="mb-8 lg:mb-20">
        <h2 className="text-black/60">Admin Dashboard</h2>
      </div>
      <div className="grid-dashboard grid lg:grid-cols-2 gap-4 lg:gap-y-14 auto-rows-[125px] lg:grid-rows-[minmax(172px,_1fr)]">
        <Card to={ROUTES.ADMIN_TRANSACTIONS()} variant="secondary">
          <CardHeader
            title="Transactions"
            description="View all transactions"
          />
        </Card>
        <Card to={ROUTES.ADMIN_INSIGHTS()}>
          <CardHeader
            title="Insights"
            description="View all consumer demographics and data"
          />
        </Card>
      </div>
    </>
  );
};

export default AdminHome;
