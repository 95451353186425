import React from "react";
import { useAuth } from "../context/AuthContext";
import { ROUTES } from "../config/routes";
import { Button } from "./Button";
import { ReactComponent as Exit } from "../assets/icons/exit.svg";
import { NavigationLink } from "./NavigationLink";

const AdminNavigation: React.FC = () => {
  const { logout } = useAuth();

  return (
    <nav className="h-full flex flex-col">
      <ul className="flex flex-col gap-2 flex-1">
        <li>
          <NavigationLink to={ROUTES.ADMIN_TRANSACTIONS()}>
            Transactions
          </NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.ADMIN_INSIGHTS()}>Insights</NavigationLink>
        </li>
      </ul>
      <div className="mt-auto pt-4">
        <Button
          variant="ghost"
          onClick={logout}
          className="text-red gap-2 flex items-center hover:text-red-400"
        >
          <Exit />
          Log out
        </Button>
      </div>
    </nav>
  );
};

export default AdminNavigation;
